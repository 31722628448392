// import Secure from '../components/secure';

export default {
  init() {
    import('../components/secure/jobs/single').then(SingleJob => {
      new SingleJob.default();
    });
  },
  finalize() {},
};
