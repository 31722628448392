// import Secure from '../components/secure';

export default {
  init() {
    import('../components/secure/resources').then(Resources => {
      new Resources.default();
    });
  },
  finalize() {},
};
