function Lazyload()
{
    var intersectOptions = {
        root: null,
        rootMargin: "0px 0px 50px 0px",
        threshold: 0
    }

    function preloadImage(img) {
        const src = img.getAttribute('data-src');
        const srcSet = img.getAttribute('data-srcset');

        if (!src && !srcSet) {
            return;
        }

        if (srcSet) {
            img.srcset = srcSet;
        }

        if (src) {
            img.src = src;
        }

        img.classList.remove("lazyload");
    }

    var observer = new IntersectionObserver(function(entries, self) {
        entries.forEach(entry => {
        if (entry.isIntersecting) {
            preloadImage(entry.target);
            self.unobserve(entry.target);
        }
        });
    }, intersectOptions);

    var lazySrcs = document.querySelectorAll('.lazyload');
    if (lazySrcs) {
        for (var i = 0; i < lazySrcs.length; i++) {
            observer.observe(lazySrcs[i]);
        }
    }
}

export default Lazyload;

