// import Secure from '../components/secure';

export default {
  init() {
    import('../components/register').then(Register => {
      new Register.default();
    });
  },
  finalize() {},
};
