// import Secure from '../components/secure';

export default {
  init() {
    import('../components/contact').then(Contact => {
      new Contact.default();
    });
  },
  finalize() {},
};
