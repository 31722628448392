import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class Menu {
  constructor() {
    this.button = document.querySelector('.menu-button');
    this.nav = document.querySelector('.header-nav');
    this.is_menu_open = false;

    if (this.button) {
      this.button.addEventListener('click', () => {
        this.openMenu();
      });
    }
  }

  openMenu() {
    this.is_menu_open = !this.is_menu_open;
    this.button.classList.toggle('is-open');
    this.nav.classList.toggle('is-open');

    if (this.is_menu_open) {
      disableBodyScroll(this.nav);
    }
    else {
      clearAllBodyScrollLocks();
    }

  }
}

export default Menu;
