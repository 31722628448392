// import Secure from '../components/secure';

export default {
  init() {
    import('../components/secure/recent-jobs').then(RecentJobs => {
      new RecentJobs.default();
    });
    import('../components/secure/recent-training').then(RecentTraining => {
      new RecentTraining.default();
    });
  },
  finalize() {},
};
