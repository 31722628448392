import Debounce from 'debounce';

function StickyHeader() {
  this.header = {
    el: document.querySelector('.header'),
    height: 0,
  };

  if (this.header.el) {
    this.header.height = document.querySelector('.header').offsetHeight;
  }
  console.log(this.header);
  this.lastPosition = window.pageYOffset;
  this.hasScrolled = false;
  this.fullScreenBanner = {
    // el: document.querySelector(".banner--fullscreen")
  };

  if (this.fullScreenBanner.el) {
    this.fullScreenBanner.box = this.fullScreenBanner.el.getBoundingClientRect();
    this.updateBackground();
  }

  if (this.header.el) {
    window.addEventListener('scroll', this.scrollHandler.bind(this));
    window.addEventListener(
      'resize',
      Debounce(this.updatePositions.bind(this), 250),
    );
  }
}

StickyHeader.prototype.updatePositions = function() {
  this.header.height = this.header.el.offsetHeight;
  if (this.fullScreenBanner.el) {
    this.fullScreenBanner.box = this.fullScreenBanner.el.getBoundingClientRect();
  }

  this.updateBackground();
  this.updateVisibility();
};

StickyHeader.prototype.updateVisibility = function() {
  if (
    window.pageYOffset > this.lastPosition &&
    window.pageYOffset > this.header.height
  ) {
    this.header.el.classList.add('is-hidden');
    document.dispatchEvent(new CustomEvent('header-hidden'));
  } else {
    this.header.el.classList.remove('is-hidden');
    document.dispatchEvent(new CustomEvent('header-visible'));
  }
  if (window.pageYOffset > this.header.height) {
    this.header.el.classList.add('is-not-top');
  }
  else {
    this.header.el.classList.remove('is-not-top');
  }
};

StickyHeader.prototype.updateBackground = function() {
  if (this.fullScreenBanner.el) {
    if (window.pageYOffset > this.fullScreenBanner.box.height) {
      this.header.el.classList.remove('is-transparent');
    } else {
      this.header.el.classList.add('is-transparent');
    }
  }
};

StickyHeader.prototype.scrolled = function() {
  if (this.hasScrolled) {
    if (Math.abs(this.lastPosition - window.pageYOffset) <= 5) {
      return;
    }

    this.updateVisibility();
    this.updateBackground();

    this.lastPosition = window.pageYOffset;
  }
};

StickyHeader.prototype.scrollHandler = function() {
  this.hasScrolled = true;
  requestAnimationFrame(this.scrolled.bind(this));
};

export default StickyHeader;
