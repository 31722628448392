// import Secure from '../components/secure';

export default {
    init() {

      var login_input = document.getElementById('user_login');
      var password_input = document.getElementById('user_pass');
      var form = document.getElementById('loginform');
      var login_button = document.getElementById('wp-submit');

      login_input.required = 'required';
      password_input.required = 'required';

      login_button.addEventListener('click', function() {
          form.classList.add('was-validated');
      })
    },
    finalize() {},
  };
