import Lazyload from '../components/lazyload';

// import Layout from '../components/layout';
import Banner from '../components/banner';
import StickyHeader from '../components/sticky-header';
import Menu from '../components/menu';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// import Accreditations from '../components/accreditations';

// import Overlay from '../util/overlay';
// import Header from '../components/header';
//import GMenu from "../components/g-menu";
// import SearchOverlay from "../components/search";
// import {animationEnd} from "cssevents";
//import Scroller from '../util/scroller';
// import ScrollMagic from 'scrollmagic';
// import objectFitImages from 'object-fit-images';

//import "bootstrap/js/dist/alert";
//import "bootstrap/js/dist/collapse";
//import "bootstrap/js/dist/dropdown";
//import "bootstrap/js/dist/modal";
//import "bootstrap/js/dist/tab";
//import "bootstrap/js/dist/util";

function callbackVisible() {
  this.classList.add('is-visible');
}

function callbackSlide() {
  this.classList.add('slide');
}

export default {
  init() {
    var html = document.querySelector('html');
    html.classList.remove('no-js');
    html.classList.add('js');

    // Mitigate IE/Edge bug showing bullets on lists which are hidden when loading the page
    if (document.documentMode || /Edge/.test(navigator.userAgent)) {
      $('ul:hidden').each(function() {
        $(this)
          .parent()
          .append($(this).detach());
      });
    }

    new Lazyload();
    new StickyHeader();
    new Menu();
    console.log('COMMON INIT');

    // new GMenu();
    // new SearchOverlay();
    // make alerts dismissable (newsletter signup forms, contact form)
    // $('.alert').alert();

    // this.productTiles = document.querySelectorAll(".product-tile");
    // this.elementsToFadeIn = document.querySelectorAll(".fade-in");

    // Banner carousel slider
    new Banner();

    this.fadeIn = document.querySelectorAll('.fadeIn');
    this.slideUp = document.querySelectorAll('.slideUp');
    this.fromLeft = document.querySelectorAll('.fromLeft');
    this.fromRight = document.querySelectorAll('.fromRight');

    // Listen to tab events to enable outlines (accessibility improvement)
    document.body.addEventListener('keyup', function(e) {
      if (e.which === 9) {
        /* tab */ document.documentElement.classList.remove('no-focus-outline');
      }
    });

    // if (document.querySelectorAll('.gp-form')) {
    //   import('../components/forms').then(Forms => {
    //     new Forms.default();
    //   });
    // }

    if (document.querySelector('#map')) {
      import('../components/map').then(Map => {
        new Map.default();
      });
    }

    if (document.querySelector('#secure-header')) {
      import('../components/secure/header').then(Header => {
        new Header.default();
      });
    }
  },
  finalize() {
    // objectFitImages('img.object-fit-cover');
    // var overlays = document.querySelectorAll('.overlay');
    // var overlay_id;
    // var overlay;

    // if (this.elementsToFadeIn) {
    //   for (var f = 0; f < this.elementsToFadeIn.length; f++) {
    //       this.elementsToFadeIn[f].scroll = new Scroller(this.elementsToFadeIn[f], {
    //           callback: callbackVisible,
    //           trigger: "top",
    //           triggerOffset: 100,
    //           // triggerOffsetPercentage: 25,
    //           once: true
    //       });
    //   }
    // }

    const dashboardMenuButton = document.querySelector('.sidebar__menu-toggle');
    const dashboardSidebar = document.querySelector('.sidebar');
    let dashboardSidebarOpen = false;

    if (dashboardMenuButton) {
      dashboardMenuButton.addEventListener('click', () => {
        dashboardSidebar.classList.toggle('is-open');
        dashboardSidebarOpen = !dashboardSidebarOpen;
        if (window.innerWidth < 1600) {
          if (dashboardSidebarOpen) {
            disableBodyScroll(dashboardSidebar)
          }
          else {
            clearAllBodyScrollLocks();
          }
        }
      });
    }

    if (this.slideUp) {
      for (var s = 0; s < this.slideUp.length; s++) {
        this.slideUp[s].scroll = new Scroller(this.slideUp[s], {
          callback: callbackSlide,
          trigger: 'top',
          // triggerOffset: 0,
          triggerOffsetPercentage: 20,
          once: true,
        });
      }
    }

    if (this.fromLeft) {
      for (var f = 0; f < this.fromLeft.length; f++) {
        this.fromLeft[f].scroll = new Scroller(this.fromLeft[f], {
          callback: callbackSlide,
          trigger: 'top',
          triggerOffsetPercentage: 20,
          once: true,
        });
      }
    }

    if (this.fromRight) {
      for (var r = 0; r < this.fromRight.length; r++) {
        this.fromRight[r].scroll = new Scroller(this.fromRight[r], {
          callback: callbackSlide,
          trigger: 'top',
          triggerOffsetPercentage: 20,
          once: true,
        });
      }
    }

    // const layout = new Layout();

    // var videos = document.querySelectorAll('video');
    // for ( var i=0, len = videos.length; i < len; i++ ) {
    //   var video = videos[i];
    //   // resume autoplay for WebKit
    //   video.play();
    //   video.addEventListener("loadeddata", onLoadedData, false);
    // }
  },
  // function onLoadedData(event)
  // {
  //     var cell = banner.getParentCell( event.target );
  //     banner.cellSizeChange( cell && cell.element );
  // }

  // if (overlays !== null) {
  //     for (var i = 0; i < overlays.length; i++) {
  //         overlay_id = overlays[i].id;
  //         overlay = new Overlay({
  //           trigger: document.querySelectorAll('.overlay__button--' + overlay_id),
  //           events: {
  //             beforeClose: function() {
  //               var videoIframe = document.getElementById(overlay_id+"iframe");
  //               // var iframeWindow = videoIframe.contentWindow ? videoIframe.contentWindow : videoIframe.contentDocument.defaultView;
  //               //     iframeWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
  //               //jQuery("#"+overlay_id+"iframe")[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
  //                   // videoIframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
  //               // var iframe = element.querySelector("iframe");
  //               var video = videoIframe.querySelector('video');

  //               if (videoIframe) {
  //                   var iframeSrc = videoIframe.src;
  //                   videoIframe.src = iframeSrc;
  //               }

  //               if (video) {
  //                 video.pause();
  //               }
  //             }
  //           }
  //         });
  //     }
  // }
};
