// import "jquery";
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/util';
// import "babel-polyfill";
require('intersection-observer');
require('nodelist-foreach-polyfill');
require('es6-promise/auto');

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

if (isIE11) {
  document.documentElement.classList.add('is-ie');
}

if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target, varArgs) {
      // .length of function is 2
      'use strict';
      if (target === null || target === undefined) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource !== null && nextSource !== undefined) {
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true,
  });
}

(function() {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail,
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

/**
 * Flickity has some odd behaviour in newer iOSs'
 */
(function() {
  let touchingCarousel = false,
    touchStartCoords;

  document.body.addEventListener('touchstart', function(e) {
    if (e.target.closest('.flickity-slider')) {
      touchingCarousel = true;
    } else {
      touchingCarousel = false;
      return;
    }

    touchStartCoords = {
      x: e.touches[0].pageX,
      y: e.touches[0].pageY,
    };
  });

  document.body.addEventListener(
    'touchmove',
    function(e) {
      if (!(touchingCarousel && e.cancelable)) {
        return;
      }

      let moveVector = {
        x: e.touches[0].pageX - touchStartCoords.x,
        y: e.touches[0].pageY - touchStartCoords.y,
      };

      if (Math.abs(moveVector.x) > 7) e.preventDefault();
    },
    { passive: false },
  );
})();

import Router from './util/Router';

// Routes
import common from './routes/common';
import pageTemplateDashboard from './routes/pageTemplateDashboard';
import pageTemplateJobs from './routes/pageTemplateJobs';
import pageTemplateTraining from './routes/pageTemplateTraining';
import singleJob from './routes/singleJob';
import singleTraining from './routes/singleTraining';
import pageTemplateFaqs from './routes/pageTemplateFaqs';
import pageTemplateResources from './routes/pageTemplateResources';
import pageTemplateUserMatrix from './routes/pageTemplateUserMatrix';
import pageTemplateSettings from './routes/pageTemplateSettings';
import pageResetPassword from './routes/pageResetPassword';
import pageTemplateRegister from './routes/pageTemplateRegister';
import pageTemplateLoginRegister from './routes/pageTemplateLoginRegister';
import pageTemplateContact from './routes/pageTemplateContact';

//import home from "./routes/home";
//import contact from "./routes/contact";
//import pageTemplateServices from "./routes/services";

const routes = new Router({
  common,
  pageTemplateDashboard,
  pageTemplateJobs,
  singleJob,
  pageTemplateTraining,
  singleTraining,
  pageTemplateFaqs,
  pageTemplateUserMatrix,
  pageTemplateRegister,
  pageTemplateLoginRegister,
  pageTemplateContact,
  pageTemplateResources,
  pageTemplateSettings,
  pageResetPassword,
  // pageTemplateServices,
  // home,
  // contact
});

function ready(func) {
  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    func();
  } else {
    document.addEventListener('DOMContentLoaded', func);
  }
}

ready(function() {
  routes.loadEvents();
});
