// import Secure from '../components/secure';

export default {
    init() {
      const submit = document.getElementById('resetpass-button');
      const form = document.getElementById('resetpassform');
      import('../components/reset-password').then(ResetPassword => {
        new ResetPassword.default();
      });
      console.log(form);
      submit.addEventListener('click', function(event) {
        form.classList.add('was-validated');
      });
    },
    finalize() {},
  };
  